import React, { useState, useEffect } from 'react';
import { Col } from "react-bootstrap";
import { Icon } from '@iconify/react';
import api from '../../../services/apiIdz';
import { Tooltip as ReactTooltip } from "react-tooltip";

import './ItemBanner.scss';
import { useNavigate } from 'react-router';

function ItemBanner({ onDelete, bannerInfo, token, handleAlert }) {

    const urlApi = `https://hdream.idzcar.com.br`
    const navigate = useNavigate()
    const [isPlaying, setIsPlaying] = useState(bannerInfo.status == "Ativo" ? true : false);
    const [tooltipId, setTooltipId] = useState("my-tooltip-play");

    const togglePlayPause = async () => {
        setIsPlaying(!isPlaying);
        try {
            const status = !isPlaying ? 'Ativo' : 'Inativo';
            console.log({ status: status })
            await api.put(`/api/banners/${bannerInfo.id}`, { status: status }, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    'Token': token
                }
            }).then(res => {
                if (res.data) {
                    handleAlert("Sucesso", `O status do banner ${bannerInfo.id} foi atualizado para '${status}' com sucesso.`, "success");
                }
            });
        } catch (error) {
            handleAlert("Error", error, "danger")
        }
    };

    const handleDeleteClick = () => {
        onDelete(bannerInfo?.id);
    };

    return (
        <>
            <Col xs={6} sm={12} md={3} lg={3}>
                <div className='ItemBanner'>
                    <div className='imagem' style={ bannerInfo.imagem_desktop ? { background: `url(${urlApi +  bannerInfo.imagem_desktop})  no-repeat center / cover` } : null}>
                        {isPlaying ? (<span><i></i> ATIVO</span>) : (<span className='warning'><i></i> Pausado</span>)}
                    </div>
                    <h2>{bannerInfo?.titulo}</h2>
                    {
                    bannerInfo?.tipo_banner === 2 ?
                        <>
                            <h6>ANO 2016 | BRANCO | KM 17.434</h6>
                        </>
                        :
                        bannerInfo?.tipo_banner !== 2 ?
                            <>
                                <h6>{bannerInfo?.tipo_banner ? 'SERVIÇO' : 'CAMPANHA'}</h6>
                            </>
                            :
                            null 
                    }
                    
                    <div className='acoes'>
                        <button className='Btns ButtonPrimary' onClick={() => { navigate(`/banners/editar/${bannerInfo.id}`) }}>Editar</button>
                        <button data-tooltip-id={tooltipId} onClick={togglePlayPause} className='Btns ButtonIcon'>
                            {isPlaying ? (
                                <i><Icon icon="material-symbols:pause"></Icon></i>
                            ) : (
                                <i><Icon icon="ph:play-fill"></Icon></i>
                            )}
                        </button>
                        <button data-tooltip-id="my-tooltip-delete" className='Btns ButtonIcon' onClick={handleDeleteClick}><i><Icon icon="mdi:trash"></Icon></i></button>
                    </div>
                </div>
            </Col>
            <ReactTooltip
                id={tooltipId}
                place="bottom"
                content={isPlaying ? "Pausar banner" : "Ativar banner"}
                style={{ backgroundColor: "#FFFFFF", color: "#000000" }}
            ></ReactTooltip>
        </>
    );
}

export default ItemBanner;


