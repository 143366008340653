import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// CSS
import "./App.scss"

// Pages IDZ Car
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Leads from './pages/Leads';
import Veiculos from './pages/Veiculos';
import VeiculosNovo from './pages/Veiculos/Novo';
import VeiculosEditar from './pages/Veiculos/Editar';
import Banners from './pages/Banners';
import BannersNovo from './pages/Banners/Novo';
import BannersEditar from './pages/Banners/Editar';
import Destaques from './pages/Destaques';
import Usuarios from './pages/Usuarios';
import Blindagem from './pages/Blindagem';
import BlindagemEditar from './pages/Blindagem/Editar';
import BlindagemVariacao from './pages/Blindagem/Variacao';
import Depoimentos from './pages/Depoimentos';
import Dados from './pages/Dados';
import Paginas from './pages/Paginas';
import PaginasNovo from './pages/Paginas/Novo';
import Minhaconta from './pages/MinhaConta';
import ImagensCarros from './pages/ImagensCarros';
import ImagensCarrosEditar from './pages/ImagensCarros/Editar';
import PaginasEdit from './pages/Paginas/Edit';

import EsteticaServicos from './pages/Estetica/Servicos';
import EsteticaCategoria from './pages/Estetica/Categorias';
import EsteticaNovo from './pages/Estetica/Novo';
import EsteticaEditar from './pages/Estetica/Edit';
import EsteticaTipo from './pages/Estetica/Tipo';
import EsteticaMarcas from './pages/Estetica/Marcas';

import CampraVeiculos from './pages/CampraVeiculos';
import CampraVeiculosContrato from './pages/CampraVeiculos/Imprimir';

import PropostaNovo from './pages/Proposta/Novo';
import PropostaNovoEditar from './pages/Proposta/Novo';
import Proposta from './pages/Proposta';
import ImprimirProposta from './pages/Proposta/Imprimir';


import ContratosNovo from './pages/Contratos/Novo';
import ContratosModelos from './pages/Contratos/Modelos';
import ContratosNovoEditar from './pages/Contratos/Novo';
import Contratos from './pages/Contratos';
import ImprimirContratos from './pages/Contratos/Imprimir';




// pages

import { CookiesProvider } from 'react-cookie';
import LoginRecovery from './pages/Login/LoginRecovery';

//styles
import './App.scss';
import { AuthProvider } from './context/useAuth';
import PrivateRoute from './components/PrivateRoute';

function App() {
    return (
        <CookiesProvider>
            <Router>
                <AuthProvider>
                    <Routes>
                        {/* START ROTAS NÃO AUTENTICADAS */}
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/newPassword/:token" element={<LoginRecovery />} />

                        {/* START ROTAS NÃO AUTENTICADAS */}

                        {/* START ROTAS AUTENTICADAS */}
                        <Route exact path='/' element={<PrivateRoute />}>

                            <Route exact path="/" element={<Navigate to="/dashboard" />} />
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/leads" element={<Leads />} />
                            <Route exact path="/veiculos" element={<Veiculos />} />
                            <Route exact path="/veiculos/novo" element={<VeiculosNovo />} />
                            <Route exact path="/veiculos/editar/:id" element={<VeiculosEditar />} />
                            <Route exact path="/banners" element={<Banners />} />
                            <Route exact path="/banners/novo" element={<BannersNovo />} />
                            <Route exact path="/banners/editar/:id" element={<BannersEditar />} />
                            <Route exact path="/destaques" element={<Destaques />} />
                            <Route exact path="/usuarios" element={<Usuarios />} />
                            <Route exact path="/blindagem" element={<Blindagem />} />
                            <Route exact path="/blindagem/editar/:id" element={<BlindagemEditar />} />
                            <Route exact path="/blindagem/variacao" element={<BlindagemVariacao />} />
                            <Route exact path="/depoimentos" element={<Depoimentos />} />
                            <Route exact path="/dados" element={<Dados />} />
                            <Route exact path="/paginas" element={<Paginas />} />
                            <Route exact path="/paginas/novo" element={<PaginasNovo />} />
                            <Route exact path="/paginas/editar/:id" element={<PaginasEdit />} />
                            <Route exact path="/minhaconta" element={<Minhaconta />} />
                            <Route exact path="/imagens" element={<ImagensCarros />} />
                            <Route exact path="/imagens/editar/:id" element={<ImagensCarrosEditar />} />

                            <Route exact path="/estetica/categorias" element={<EsteticaCategoria />} />
                            <Route exact path="/estetica/produtos" element={<EsteticaServicos />} />
                            <Route exact path="/estetica/novo" element={<EsteticaNovo />} />
                            <Route exact path="/estetica/tipos" element={<EsteticaTipo />} />
                            <Route exact path="/estetica/marcas" element={<EsteticaMarcas />} />
                            <Route exact path="/estetica/servicos/:id" element={<EsteticaEditar />} />

                            <Route exact path="/campraveiculos" element={<CampraVeiculos />} />
                            <Route exact path="/campraveiculos/imprimir/:id" element={<CampraVeiculosContrato />} />

                            <Route exact path="/proposta/" element={<Proposta />} />
                            <Route exact path="/proposta/novo" element={<PropostaNovo />} />
                            <Route exact path="/proposta/editar/:id" element={<PropostaNovoEditar />} />
                            <Route exact path="/proposta/imprimir/:id" element={<ImprimirProposta />} />

                            <Route exact path="/contratos/" element={<Contratos />} />
                            <Route exact path="/contratos/modelos" element={<ContratosModelos />} />
                            <Route exact path="/contratos/novo" element={<ContratosNovo />} />
                            <Route exact path="/contratos/editar/:id" element={<ContratosNovoEditar />} />
                            <Route exact path="/contratos/imprimir/:id" element={<ImprimirContratos />} />
                            
                            


                        </Route>
                        {/* END ROTAS AUTENTICADAS */}

                        {/* START NOT FOUND*/}
                        <Route path="/*" element={<h1>404</h1>} />
                        {/* END NOT FOUND*/}
                    </Routes>
                </AuthProvider>
            </Router>
        </CookiesProvider>
    );
}

export default App;
