import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import './UsuariosList.scss';
import { useAuth } from "../../../context/useAuth";
import { limitarString } from '../../../utils/formatFunctions';
import { dateToLocaleStringCustom } from '../../../utils/dates';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alerts from "../../Alerts";

import Iconi7 from "./img/icon-i7.svg";
import Iconi7disable from "./img/icon-i7-disable.svg";


import 'react-tooltip/dist/react-tooltip.css'

function Tabela({userList, filter, nameFilter, showModalEdit, showModalDelete }) {

    const navigate = useNavigate()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

   

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            );
        }
    };

    const { user, loading, token, isAdmin } = useAuth()

    function dateToLocaleStringCustom(date) {
        const options = {
            day: 'numeric',
            month: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return date.toLocaleString('pt-BR', options);
    }

    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <div className="UsuariosList">
                <div className="card">
                    <DataTable
                        value={userList}
                        paginatorClassName={"paginator"}
                        paginatorTemplate={paginatorTemplate}
                        sortField="createdAt"
                        sortOrder={-1}
                        paginator
                        rows={12}>

                        <Column
                            field="status"
                            header="STATUS"
                            style={{ width: '14%' }}
                            body={(rowData) => <span><i className={`${rowData.status}`}></i> <strong>{dateToLocaleStringCustom(rowData.status)}</strong></span>}
                            bodyClassName="status-column">
                        </Column>

                        <Column field="nome"
                            header="NOME"
                            bodyClassName="title-column name-column"
                            style={{ width: '20%' }} >
                        </Column>

                        <Column field="email"
                            header="EMAIL"
                            bodyClassName="title-column mail-column"
                            style={{ width: '15%' }} >
                        </Column>

                        <Column field="categoria"
                            header="CATEGORIA"
                            bodyClassName="status-categoria"
                            body={(rowData) => 
                                rowData.tipo_usuario == "Administrador" ? (<span><i><Icon className="icons" icon="nimbus:user" /></i><strong>Administrador</strong></span>):
                                rowData.tipo_usuario == "Super Admin" ? (<span><i><Icon className="icons" icon="tabler:user-cog" /></i><strong>Super Admin</strong></span>):<></>
                            }
                            style={{ width: '15%' }} >
                        </Column>

                        <Column
                            field="Actions"
                            header=""
                            style={{ width: '22%' }}
                            bodyClassName="actions-column"
                            body={(rowData) => {
                                return (
                                    <>
                                        <button className='Btns ButtonIcon' onClick={(e) => { showModalDelete(rowData.id) }}> 
                                            <i><Icon className="icons" icon="mdi:trash" /></i>
                                        </button>
                                        {isAdmin() && (
                                            <button className='Btns ButtonIcon'>
                                                <i><Icon className="icons" icon="fluent-mdl2:modeling-view" /></i>
                                            </button>
                                        )}
                                        <button className='Btns View' onClick={() => { showModalEdit(rowData) }}>
                                            Editar <i><Icon className="icons" icon="ri:add-fill" /></i>
                                        </button>
                                        
                                    </>
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
            <ReactTooltip id="my-tooltip-1" place="bottom" content="Publicar" />
            <ReactTooltip id="my-tooltip-2" place="bottom" content="Já publicado" />
        </>
    );
}
export default Tabela;

