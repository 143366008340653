import React, { useRef, useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import api from '../../../services/apiIdz';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Form from 'react-bootstrap/Form';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Importa o CSS

import logo from './img/logoDream.png';

import './Imprimir.scss';

const MyComponentToPrint = React.forwardRef((props, ref) => {
    console.log("props", props)


    function numeroParaTexto(valor) {
        const unidade = ["", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove"];
        const dezena = ["", "dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"];
        const centena = ["", "cem", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"];
        const especial = ["dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"];

        function converter(valor) {
            if (valor < 10) return unidade[valor];
            if (valor < 20) return especial[valor - 10];
            if (valor < 100) return dezena[Math.floor(valor / 10)] + (valor % 10 !== 0 ? " e " + unidade[valor % 10] : "");
            if (valor < 1000) return centena[Math.floor(valor / 100)] + (valor % 100 !== 0 ? " e " + converter(valor % 100) : "");
            if (valor < 1000000) return converter(Math.floor(valor / 1000)) + " mil" + (valor % 1000 !== 0 ? " e " + converter(valor % 1000) : "");
            return converter(Math.floor(valor / 1000000)) + " milhões" + (valor % 1000000 !== 0 ? " e " + converter(valor % 1000000) : "");
        }

        const valorTexto = converter(valor);
        return valorTexto.charAt(0).toUpperCase() + valorTexto.slice(1);
    }


    return (
        <div ref={ref} className='pdf consignacao'>
            <div className='paginas'>
                <img className='logo' src={logo} />
                <h2>CONTRATO PARTICULAR DE CONSIGNAÇÃO PARA VENDA DE VEÍCULO</h2>
                <p>Por este instrumento de CONTRATO PARTICULAR DE CONSIGNAÇÃO PARA VENDA DE VEÍCULO , de um lado: {props.proposta?.nome || '---'}, inscrito no CPF/CNPJ sob o nº. {props.proposta?.cpf || '---'}, com endereço em {props.proposta?.endereco.endereco || '---'},  {props.proposta?.endereco.bairro || '---'},  {props.proposta?.endereco.cidade || '---'},  {props.proposta?.endereco.estado || '---'}, doravante denominado CONSIGNANTE/PROPRIETÁRIO
e, de outro lado:</p>
                <p>DR IMPORTACOES SA, pessoa jurídica de direito privado, inscrita no CNPJ 39.730.843/0001-89, com sede em São Paulo/SP, na Avenida Nove de Julho, 3229, Bairro Jardim Paulista doravante denominada CONSIGNATÁRIO.</p>
                <p>As partes ajustam entre si nos termos que seguem em relação à consignação, que se obrigam a cumprir por si e seus sucessores, mediante as cláusulas e condições:</p>
                <h2>CLÁUSULA PRIMEIRA - OBJETO DA CONSIGNAÇÃO</h2>
                <h2>CLÁUSULA SEGUNDA – O VALOR A SER PAGO AO CONSIGNANTE</h2>
                <p>Fica acordado entre as partes que A CONSIGNATÁRIA pagará ao CONSIGNANTE após a venda do OBJETO o valor de R$ {props.proposta?.valor || '---'} ou a porcentagem de {props.proposta?.porcentagem || '---'} do valor de venda do veículo; Caso o CONSIGNANTE retire o veículo da responsabilidade da loja, deverá arcar com o custo das despesas do mesmo: anúncios, preparação geral e laudo cautelar no valor de ( R$ {props.proposta?.multa || '---'} ) conforme contrato.</p>
                <h2>CLÁUSULA TERCEIRA – DO OBJETO </h2>
                <p>O (a) consignante declara para todos os efeitos legais que é o senhor(a) e legitimo (a) possuidor (a) do veículo automotor caracterizado preâmbulo deste, por regular aquisição e mais, que o mesmo encontra-se livre e desembaraçado de quaisquer ônus ou gravames, seja, convencional ou não, que se constitui no objeto de presente. </p>
                <h2>Parágrafo único</h2>
                <p>O fato de o certificado do veículo estar em nome de terceiros ou em nome do (a) consignante em nada afeta a integral responsabilidade perante terceiros ou qualquer autoridade que seja civil, militar ou judiciária, por toda e qualquer ocorrência ou irregularidade que recaia ou venha a recair até a presente data, notadamente no que se refere as multas por infrações de trânsito, sendo ainda de responsabilidade do consignante qualquer acidente com ou sem vítimas, no qual, eventualmente, tenha sido parte, assumindo o (a) consignante, ainda, a responsabilidade civil ou penal, respondendo também integralmente pela legalidade do veículo, respondendo pela evicção de direto no caso de concretização da venda do mesmo pela consignatária, sendo certo que os itens acima especificados representam simples enumeração, inclusive por perdas e danos decorrentes e lucros cessantes. </p>
                <h2>CLÁUSULA QUARTA - DA GUARDA DO VEÍCULO </h2>
                <p>A consignatária assume integral responsabilidade pela guarda e zelo do veículo objeto do presente objeto contrato durante o tempo em que movendo sua venda . </p>
                
            </div>
            <div className='paginas timbrado2'>
                <img className='logo' src={logo} />
                <h2>CLÁUSULA QUINTA- DO PRAZO DE VALIDADE DO CONTRATO</h2>
                <p>O prazo de validade deste contrato é indeterminado, podendo ser suspenso pelo (a) consignante deste que a consignatária seja comunicada com uma antecedência mínima de 24 horas. </p>
                <h2>CLÁUSULA SEXTA - DA GARANTIA DO VEÍCULO </h2>
                <p>O consignante fica responsável pela garantia conforme leis do PROCON. Caso o consignatário não assuma as responsabilidades acima, a consignatária poderá emitir uma letra de câmbio no valor a ser reclamado pelo comprador do veículo.</p>
                <h2>CLÁUSULA SÉTIMA - DO FORO </h2>
                <p>Fica eleito o foro da comarca desta cidade, para dirimir quaisquer questões oriundas do presente contrato, com renúncia expressa de qualquer outro, por mais privilegiado que seja ou venha tornar. </p>
                <h2>Responsabilidades sobre multas </h2>
                <p>Declaro, para todos os efeitos legais, que responsabilizo-me por todas as multas, acidentes ou infrações rodoviárias, que existirem até o presente data, sobre o veículo caracterizado no preâmbulo deste e por mim consignado, estando assim pronto a efetuar o pagamento sem mais nenhum aviso a cobrança poderá ser feita mediante saque a vista contra a minha pessoa ou critério da consignatária, por meio judicial ou extrajudicial, podendo ainda ser feita compensação com eventuais créditos que tenha junto á consignatária, seja de que natureza for fica ainda ressalva direito do órgão de trânsito de cobrar as multas relativas ás infrações cometidas anteriormente a está data, ainda que tenham sido levantadas, sendo todas elas de minha inteira responsabilidade . </p>
                <h2>DO PRAZO: A presente autorização de venda será por prazo indeterminado.</h2>


                <div className='assinatura'>
                    <div className='parte1'>Dream Route</div>
                    <div className='parte2'>CNPJ</div>
                    <div className='dados'>
                        <p>
                            contato@creamroute.com.br<br />
                        </p>
                    </div>
                </div>

                <div className='assinatura cliente'>
                    <div className='parte1'>{props.proposta?.nome || '---'}</div>
                    <div className='parte2'>CNPJ/CPF</div>
                    <div className='dados'>
                        <p>{props.proposta?.email || '---'}<br /></p>
                    </div>
                </div>
            </div>
        </div>
    );
});

function ImprimirProposta() {
    const componentRef = useRef();
    const { id } = useParams();
    const [proposta, setProposta] = useState(null);

    useEffect(() => {
        const fetchProposta = async () => {
            try {
                const response = await api.get(`/api/vendaseucarro/${id}`);
                console.log("vendaseucarro", response.data)
                setProposta(response.data);
            } catch (error) {
                console.error('Erro ao buscar a proposta:', error);
            }
        };

        fetchProposta();
    }, [id]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'MinhaPaginaPDF',
        onAfterPrint: () => console.log('PDF gerado com sucesso!'),
    });

    const handleDownloadPDF = async () => {
        // Salva os estilos originais
        const originalWidth = componentRef.current.style.width;
        const originalHeight = componentRef.current.style.height;
    
        // Define os estilos para impressão
        componentRef.current.style.width = '1082px';
        componentRef.current.style.height = '1530px';
    
        // Inicializa o jsPDF
        const pdf = new jsPDF('p', 'pt', 'a4');
        const elementsToPrint = componentRef.current.querySelectorAll('.capa, .paginas, .verso');
    
        for (let i = 0; i < elementsToPrint.length; i++) {
            const element = elementsToPrint[i];
    
            try {
                // Verifica se o dispositivo é iOS
                const isIOS = !!window.navigator.userAgent.match(/iPhone|iPad|iPod/);
    
                // Define a escala baseada no dispositivo
                const canvasScale = isIOS ? 0.7 : 1;
                // Captura o elemento usando html2canvas com CORS habilitado
                const canvas = await html2canvas(element, {
                    scale: canvasScale,
                    useCORS: true,
                    allowTaint: false, // Evita capturar recursos sem CORS válido
                });
    
                // Gera a imagem da captura
                const imgData = canvas.toDataURL('image/jpeg', 0.7);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    
                if (i > 0) {
                    pdf.addPage();
                }
    
                // Adiciona a imagem ao PDF
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, '', 'FAST');
            } catch (error) {
                console.error("Erro ao capturar elemento para PDF:", error);
            }
        }
    
        // Salva o PDF com o nome especificado
        pdf.save('Proposta_1021.pdf');
    
        // Retorna os estilos originais
        componentRef.current.style.width = originalWidth;
        componentRef.current.style.height = originalHeight;
    };
    

    return (
        <div>
            <MyComponentToPrint ref={componentRef} proposta={proposta} />
            <div className="barPrint">
                <button className='Btns ButtonPrimary' onClick={handleDownloadPDF}>Baixar Proposta</button>
            </div>
        </div>
    );
}

export default ImprimirProposta;
