import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import api from "../../../services/apiIdz";
import "./Imprimir.scss";

const MyComponentToPrint = React.forwardRef(({ contrato }, ref) => {
    return (
        <div ref={ref} className="pdfcontrato">
            {/* Cabeçalho */}
            <div className="paginas timbrado2">
                <h1 className="titulo">CONTRATO DE BLINDAGEM AUTOMOTIVA</h1>
                <h2 className="subtitulo">
                    COM FORNECIMENTO DE MATERIAL DE PROTEÇÃO BALÍSTICA E MÃO DE OBRA N° {contrato?.numero || "______"}
                </h2>
                <p>
                    Pelo presente instrumento particular de prestação de serviços de blindagem automotiva com fornecimento
                    de material de proteção balística e mão de obra, a saber:
                </p>

                {/* Contratante */}
                <h3>CONTRATANTE</h3>
                <p>
                    Nome: {contrato?.comprador?.nome || "____"}, portador do documento{" "}
                    {contrato?.comprador?.doc || "____"}, residente e domiciliado na{" "}
                    {contrato?.comprador?.logradouro || "____"}, n° {contrato?.comprador?.numero || "____"}, Bairro:{" "}
                    {contrato?.comprador?.bairro || "____"}, na cidade de {contrato?.comprador?.cidade || "____"}, Estado:{" "}
                    {contrato?.comprador?.estado || "____"}, CEP: {contrato?.comprador?.cep || "____"}.
                </p>

                {/* Contratada */}
                <h3>CONTRATADA</h3>
                <p>
                    {contrato?.vendedor?.razao_social || "____"}, empresa estabelecida na{" "}
                    {contrato?.vendedor?.logradouro || "____"}, nº {contrato?.vendedor?.numero || "____"}
                    {contrato?.vendedor?.complemento ? `, ${contrato?.vendedor?.complemento}` : ""}, Bairro:{" "}
                    {contrato?.vendedor?.bairro || "____"}, {contrato?.vendedor?.cidade || "____"} -{" "}
                    {contrato?.vendedor?.estado || "____"}, CEP {contrato?.vendedor?.cep || "____"}, inscrita no CNPJ/MF
                    sob o número {contrato?.vendedor?.cnpj || "____"}, Insc. Estadual{" "}
                    {contrato?.vendedor?.inscricao_estadual || "____"}, representada por seu administrador{" "}
                    {contrato?.vendedor?.representante || "____"}, portador da carteira de identidade n°{" "}
                    {contrato?.vendedor?.identidade || "____"}.
                </p>

                {/* Cláusulas */}
                {contrato?.secao?.map((secao, index) => (
                    <div key={`secao-${index}`} className="clausula">
                        <p>
                            <strong>{secao.clausula}</strong>
                        </p>
                        <p dangerouslySetInnerHTML={{ __html: secao.descricao }}></p>
                    </div>
                ))}

                <div className="assinaturas">
                    <p className="data">
                        {contrato?.data_assinatura
                            ? `São Paulo, ${new Date(contrato.data_assinatura).toLocaleDateString('pt-BR', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric',
                            })}`
                            : `São Paulo, ${new Date().toLocaleDateString('pt-BR', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric',
                            })}`}
                    </p>
                    <div className="assinatura-blocos">
                        <div className="assinatura-bloco">
                            <p className="ass">________________________________________</p>
                            <p className="pessoa">{contrato?.comprador?.nome || "CONTRATANTE"}</p>
                        </div>
                        <div className="assinatura-bloco">
                            <p className="ass">________________________________________</p>
                            <p className="pessoa">{contrato?.vendedor?.razao_social || "CONTRATADA"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});




function ImprimirContrato() {
    const componentRef = useRef();
    const { id } = useParams();
    const [contrato, setContrato] = useState(null);

    useEffect(() => {
        const fetchContrato = async () => {
            try {
                const response = await api.get(`/api/contrato/${id}`);
                setContrato(response.data);
            } catch (error) {
                console.error("Erro ao buscar o contrato:", error);
            }
        };

        fetchContrato();
    }, [id]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Contrato_${id}`,
        onAfterPrint: () => console.log("Contrato impresso com sucesso!"),
    });

    return (
        <div>
            {contrato && <MyComponentToPrint ref={componentRef} contrato={contrato} />}
            <div className="barPrint">
                <button className="Btns ButtonPrimary" onClick={handlePrint}>
                    Imprimir Contrato
                </button>
            </div>
        </div>
    );
}

export default ImprimirContrato;
