import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import "./Edit.scss";
import { useAuth } from "../../../context/useAuth";
import api from "../../../services/apiIdz";

function UsuariosEditModal({ handleAlert, getUserList, showEdit, data }) {
    const [wrong_name, setWrong_name] = useState(false);
    const [wrong_email, setWrong_email] = useState(false);
    const [wrong_type, setWrong_type] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const { token, isAdmin } = useAuth();

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [tipo, setTipo] = useState(null);
    const [acessos, setAcessos] = useState([]);
    const [ativo, setAtivo] = useState(true);

    const placeholdersTipo = [
        { label: 'Administrador', value: 'Administrador' },
        { label: 'Consultor', value: 'Consultor' },
        { label: 'Editor', value: 'Editor' },
        { label: 'Super Admin', value: 'Super Admin' }
    ];

    // Lista de módulos disponíveis
    const modulos = [
        { id: 1, label: 'Dashboard' },
        { id: 2, label: 'Leads' },
        { id: 3, label: 'Veículos' },
        { id: 4, label: 'Consignação' },
        { id: 5, label: 'Blindagem' },
        { id: 6, label: 'Estética' },
        { id: 7, label: 'Marketing' },
        { id: 8, label: 'Configurações' },
        { id: 9, label: 'Contratos' }
    ];

    // Manipulador para selecionar e desmarcar módulos de acesso
    const handleAcessoChange = (moduloId) => {
        setAcessos((prevAcessos) =>
            prevAcessos.includes(moduloId)
                ? prevAcessos.filter((id) => id !== moduloId)
                : [...prevAcessos, moduloId]
        );
    };

    useEffect(() => {
        if (showEdit) {
            setShowModal(true);
            if (data) {
                setNome(data.nome);
                setEmail(data.email);
                setTipo(data.tipo_usuario);
                setAcessos(data.acessos || []);
                setAtivo(data.status.toLowerCase() === "ativo" || data.status === true || data.status === 1);
            }
        }
    }, [showEdit, data]);

    const handleClose = () => {
        setWrong_name(false);
        setWrong_email(false);
        setWrong_type(false);
        setShowModal(false);
    };

    const submitEdit = async () => {
        if (nome === '') {
            handleAlert("Credenciais Insuficientes", "Por favor, insira um nome", "danger");
            setWrong_name(true);
        } else if (email === '') {
            handleAlert("Credenciais Insuficientes", "Por favor, insira um email válido", "danger");
            setWrong_email(true);
        } else if (tipo === '') {
            handleAlert("Credenciais Insuficientes", "Por favor, informe o nível de acesso", "danger");
            setWrong_type(true);
        } else {
            setWrong_name(false);
            setWrong_email(false);
            setWrong_type(false);

            await api.put(`/api/usuarios/${data.id}`, {
                nome,
                email,
                senha: "12345678",
                tipo_usuario: tipo,
                status: ativo,
                acessos: isAdmin() ? acessos : [] // Envia acessos se for admin
            }, {
                headers: {
                    "Token": token,
                    "Authorization": process.env.REACT_APP_API_KEY
                }
            }).then(res => {
                if (res) {
                    getUserList();
                    handleAlert("Sucesso", "Os dados foram editados com êxito.", "success");
                    handleClose();
                }
            });
        }
    };

    return (
        <>
            <Modal show={showModal} className="popup edit">
                <Modal.Header>
                    <Modal.Title>Alteração de usuário</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                className={wrong_name ? "wrongField" : ""}
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                type="text"
                                placeholder=""
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                className={wrong_email ? "wrongField" : ""}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="text"
                                placeholder=""
                            />
                        </Form.Group>
                        <Form.Label className="Label">
                            <Form.Label>Tipo</Form.Label>
                            <div className={`dropdownWrongField ${wrong_type ? "wrongField" : ""}`}>
                                <Dropdown
                                    value={tipo}
                                    onChange={(e) => setTipo(e.value)}
                                    options={placeholdersTipo}
                                    placeholder="Selecione"
                                    className="Dropdown"
                                />
                            </div>
                        </Form.Label>
                        {isAdmin() && (
                            <Form.Group className="mb-3">
                                <Form.Label>Módulos de Acesso</Form.Label>
                                {modulos.map((modulo) => (
                                    <Form.Check
                                        key={modulo.id}
                                        type="checkbox"
                                        label={modulo.label}
                                        checked={acessos.includes(modulo.id)}
                                        onChange={() => handleAcessoChange(modulo.id)}
                                    />
                                ))}
                            </Form.Group>
                        )}
                        <Form.Check
                            checked={ativo}
                            className="Label"
                            type="switch"
                            label={ativo ? "Usuário ativo" : "Usuário desativado"}
                            onChange={() => setAtivo(!ativo)}
                        ></Form.Check>
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={submitEdit} className="Btns ButtonPrimaryLight">
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UsuariosEditModal;
