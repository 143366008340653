import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Icon } from '@iconify/react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from 'react-router-dom';

import 'react-tooltip/dist/react-tooltip.css';

import './ContratoList.scss';

function ContratoList({ categorias, handleDelete }) {
    const navigate = useNavigate();

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            );
        }
    };

    useEffect(() => {
        if (categorias) {
            console.log("categorias", categorias);
        }
    }, [categorias]);

    const formatarData = (data) => {
        if (!data) return "N/A";
        const [ano, mes, dia] = data.split("-");
        return `${dia}/${mes}/${ano}`;
    };

    return (
        <>
            <div className="UsuariosList">
                <div className="card">
                    <DataTable
                        value={categorias}
                        paginatorClassName={"paginator"}
                        paginatorTemplate={paginatorTemplate}
                        sortField="createdAt"
                        sortOrder={-1}
                        paginator
                        rows={12}>

 
                        <Column
                            field="numero"
                            header="CÓDIGO"
                            bodyClassName="title-column code-column"
                            style={{ width: '20%' }}
                        ></Column>

                        <Column
                            field="dataCriacao"
                            header="DATA CRIAÇÃO"
                            body={(rowData) => formatarData(rowData.dataCriacao)} // Chama a função para formatar a data
                            bodyClassName="title-column date-column"
                            style={{ width: '20%' }}
                        ></Column>

                        <Column
                            field="nomeComprador"
                            header="NOME COMPRADOR"
                            bodyClassName="title-column name-column"
                            style={{ width: '20%' }}
                        ></Column>

                        {/* Coluna de Modelo do Veículo */}
                        <Column
                            field="modeloVeiculo"
                            header="MODELO VEÍCULO"
                            bodyClassName="title-column model-column"
                            style={{ width: '25%' }}
                        ></Column>

                        {/* Coluna de Ações */}
                        <Column
                            field="Actions"
                            header="AÇÕES"
                            style={{ width: '25%' }}
                            bodyClassName="actions-column"
                            body={(rowData) => {
                                return (
                                    <>
                                        <button className='Btns ButtonIcon' onClick={() => handleDelete(rowData.id)}>
                                            <i><Icon className="icons" icon="mdi:trash" /></i>
                                        </button>
                                        <button className='Btns ButtonIcon' onClick={() => navigate(`/contratos/imprimir/${rowData.id}`)}>
                                            <i><Icon className="icons" icon="ri:printer-line" /></i>
                                        </button>
                                        <button className='Btns View' onClick={() => navigate(`/contratos/editar/${rowData.id}`)}>
                                            Editar <i><Icon className="icons" icon="ri:add-fill" /></i>
                                        </button>
                                    </>
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
            <ReactTooltip id="my-tooltip-1" place="bottom" content="Publicar" />
            <ReactTooltip id="my-tooltip-2" place="bottom" content="Já publicado" />
        </>
    );
}

export default ContratoList;
