import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import api from "../../../services/apiIdz";
import { useMediaQuery } from 'react-responsive';

// Components
import Navbar from "../../../components/Navbar";
import Searce from '../../../components/Estetica/Searce';
import Filter from '../../../components/Estetica/Filter';
import ContratoModeloList from '../../../components/Contrato/ContratoModeloList';
import ModalDelete from '../../../components/Contrato/ModalDelete';
import Alerts from '../../../components/Alerts';

// Styles
import '../Contratos.scss';

// Imagens
import logo from '../../Dashboard/img/logo-idzcar.png';

function Contratos() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const navigate = useNavigate();

    const [categorias, setCategorias] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    // Alert handler
    const handleAlert = (title, description, type) => {
        setIsVisible(true);
        setTitle(title);
        setDescription(description);
        setType(type);
    };

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setIsVisible(false);
            }, 8000);
        }
    }, [isVisible]);

    // Fetch contracts data
    const getCategoria = async () => {
        try {
            const res = await api.get(`/api/contrato?tipo=0`);
            if (res.status === 200) {
                const mappedData = res.data.map(item => ({
                    id: item.id || "",
                    titulo: item.titulo || "N/A",
                }));
                setCategorias(mappedData);
                setFiltered(mappedData); // Initialize filtered with all data
            }
        } catch (error) {
            console.error("Erro ao buscar contratos:", error);
            handleAlert("Erro", "Falha ao carregar os contratos.", "danger");
        }
    };

    useEffect(() => {
        getCategoria();
    }, []);

    // Filter logic: Combine search and status filters
    const applyFilters = () => {
        let filteredData = categorias;

        if (search) {
            filteredData = filteredData.filter(item =>
                (item.nomeComprador || "").toLowerCase().includes(search.toLowerCase())
            );
        }

        if (status) {
            filteredData = filteredData.filter(item => item.status === status);
        }

        setFiltered(filteredData);
    };

    useEffect(() => {
        applyFilters();
    }, [search, status, categorias]);

    const handleSearch = (searchData) => {
        setSearch(searchData);
    };

    const handleStatus = (statusData) => {
        setStatus(statusData);
    };

    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleDelete = (id) => {
        setDeleteId(id);
        setDeleteModalShow(true);
    };

    const refreshData = () => {
        getCategoria();
    };

    return (
        <>
            {isVisible && <Alerts title={isTitle} description={initDescription} type={initType} />}
            <Navbar menu={false} handleMenuToggle={() => {}} />
            <div className="Content ContratosList">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? (
                                <div className='HeaderDashboard'>
                                    <a href='/dashboard' className='logo'><img src={logo} /></a>
                                    <a className='menu'><Icon icon="jam:menu" /></a>
                                </div>
                            ) : (
                                <div className='HeaderLeads'>
                                    <i><Icon icon="tabler:user-star" ></Icon></i>
                                    <h2>Modelos de Contratos <br />
                                        <span>Acompanhe e gerencie informações de clientes em potencial.</span></h2>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableEstetica'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsEstetica'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={() => navigate("/contratos/novo")}>
                                            Novo <i><Icon icon='ri:add-fill' /></i>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listEstetica'>
                                        <ContratoModeloList
                                            categorias={filtered}
                                            handleDelete={handleDelete}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </div>

            <ModalDelete
                show={deleteModalShow}
                handleClose={() => setDeleteModalShow(false)}
                deleteId={deleteId}
                handleAlert={handleAlert}
                refreshData={refreshData}
                endpoint="contrato"
                title="Deletar Contrato"
                bodyMessage="Deseja realmente deletar este contrato?"
            />
        </>
    );
}

export default Contratos;
