import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';
import { useParams } from 'react-router-dom';

//components
import Navbar from "../../../components/Navbar";
import Alerts from '../../../components/Alerts';
import ModalImagem from '../../../components/ImagensCarros/Imagem';
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/apiIdz';

//styles
import './EditarImagensCarros.scss';



function EditarImagensCarros() {

    const { id } = useParams();
    const { token } = useAuth()
    const [categoria, setCategoria] = useState([]);

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    const [showImagem, setshowImagem] = useState(false);
    const [idModal, setIdModal] = useState();

    const showModalImagem = (idModelo) => {
        setshowImagem(!showImagem);
        setIdModal(idModelo);
    }



    const getCategoria = async () => {
        await api.get(`/api/modelos/marca/${id}?origem=site&agrupado=1`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'token': token,
            }
        }).then(res => {
            
            if (res.status === 200) {
                const modelos = res.data;
                const categorias = modelos.map(modelo => ({ id: modelo.id, titulo: modelo.titulo }));
                setCategoria(categorias);
            }
        }).catch(error => {
            console.error("Erro ao buscar categorias:", error);
        });
    };

    useEffect(() => {
        getCategoria()
    }, [])

    
    const [imagensPorCategoria, setImagensPorCategoria] = useState([]);

    const carregarImagens = async (idModelo) => {
        try {
            const res = await api.get(`/api/modelo-imagem?modelo=${idModelo}`);
            if (res.status === 200) {
                let imagens = Array.isArray(res.data) ? res.data : [res.data];
                setImagensPorCategoria((prevState) => ({
                    ...prevState,
                    [idModelo]: imagens
                }));
                console.log("imagensPorCategoria: ", imagensPorCategoria)
            }
        } catch (error) {
            console.error("Erro ao buscar imagens da categoria:", error);
        }
    };
    
    useEffect(() => {
        categoria.forEach(cat => carregarImagens(cat.id));
    }, [categoria]);

    const handlePrincipalImagem = async (idImagem, IdModelo) => {
        try {
            await api.put(`/api/modelo-imagem/${idImagem}`, { principal: 1 }, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    'Token': token
                }
            }).then(res => {
                if (res.data) {
                    handleAlert("Sucesso", `A imagem foi favoritada com sucesso.`, "success")
                    carregarImagens(IdModelo);
                }
            });
        } catch (error) {
            handleAlert("Error", error, "danger")
        }
    }; 

    const handleExcluirImagem = async (idImagem, IdModelo) => {
        try {
            await api.delete(`/api/modelo-imagem/${idImagem}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    'Token': token
                }
            }).then(res => {
                if (res.data) {
                    handleAlert("Sucesso", `A imagem foi deletada com sucesso.`, "success")
                    carregarImagens(IdModelo);
                }
            });
        } catch (error) {
            handleAlert("Error", error, "danger")
        }
    };



    return (
        <>

            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar />
            <div className="Content Blindagem">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderBlindagem'>
                                <i><Icon icon="wpf:security-checked" ></Icon></i>
                                <h2>Modelos<br /><span>Organize aqui as fotos dos modelos de veículos cadastrados.</span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='formImagensCarros'>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='HeaderForm'>
                                        <h2>{categoria?.titulo}Marca</h2>
                                        <div className='statusVeiculo'>
                                            <Form.Check className='Label' type="switch"
                                                label=""
                                                checked=""
                                                onChange=""
                                            ></Form.Check>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={8} lg={12} className='text-right'>
                                    <Form.Group className="Form">
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={12}>
                                            <Accordion onSelect={(selectedIndex) => carregarImagens(categoria[selectedIndex].id)}>
                                                {Array.isArray(categoria) && categoria.map((espec, index) => (
                                                    <Accordion.Item eventKey={index} key={index}>
                                                        <Accordion.Header>
                                                            <h4>{espec.titulo}</h4>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='cadastroItem'>
                                                                    {imagensPorCategoria[espec.id] && imagensPorCategoria[espec.id].map((imagem) => (
                                                                        <div className="item" style={{ background: `#ededed url(https://hdream.idzcar.com.br${imagem.imagem}) no-repeat center / contain` }}>
                                                                            <div className='Acoes'>
                                                                                <div className='picture'>
                                                                                    <button className='Btns ButtonIcon' onClick={() => handleExcluirImagem(imagem.id, espec.id)}>
                                                                                        <i><Icon icon="mdi:trash"></Icon></i>
                                                                                    </button>
                                                                                    <button className='Btns ButtonIcon' onClick={() => handlePrincipalImagem(imagem.id)}>
                                                                                        <i><Icon icon="ic:round-star"></Icon></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                <div className='addVariacao'>
                                                                    <Button className='Btns Add' onClick={() => { showModalImagem(espec.id) }}><Icon icon="ic:baseline-plus"></Icon></Button>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                            </Col>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} className='acoes'>
                                                    <Form.Group className="">
                                                        <Button className='Btns ButtonSecondary'>Voltar</Button>
                                                        <Button className='Btns ButtonPrimaryLight' onClick="">Salvar</Button>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Row>

                </Container>
                <ModalImagem show={showImagem} idModal={idModal} imagemAlert={handleAlert} />
            </div>
           
        </>
    );
}
export default EditarImagensCarros;