import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import "./ModalDelete.scss";
import api from "../../../services/apiIdz";
import { useAuth } from "../../../context/useAuth";

function ModalDelete({
    show,
    handleClose,
    deleteId,
    handleAlert,
    refreshData,
    endpoint,
    title = "Deletar Item",
    bodyMessage = "Deseja realmente deletar este item?"
}) {
    const [showModal, setShowModal] = useState(false);
    const { token } = useAuth();

    const deleteData = async () => {
        try {
            const response = await api.delete(`/api/${endpoint}/${deleteId}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token,
                },
            });

            if (response.status === 200) {
                handleAlert("Sucesso", "O item foi deletado com sucesso.", "success");
                refreshData(); // Atualiza a lista de dados
                setShowModal(false);
                handleClose();
            } else {
                handleAlert("Erro", "Falha ao deletar o item. Tente novamente.", "danger");
            }
        } catch (error) {
            console.error("Erro ao deletar item:", error.response?.data || error.message);
            handleAlert("Erro", "Não foi possível deletar o item. Tente novamente.", "danger");
        }
    };

    useEffect(() => {
        setShowModal(show);
    }, [show]);

    return (
        <Modal show={showModal} onHide={() => { handleClose(); setShowModal(false); }}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{bodyMessage}</p>
                <Form>
                    <Form.Group className="text-center">
                        <Button variant="danger" onClick={deleteData} className="Btns ButtonPrimaryLight">
                            Sim
                        </Button>
                        <Button
                            onClick={() => {
                                handleClose();
                                setShowModal(false);
                            }}
                            variant="secondary"
                            className="Btns ButtonSecondaryLight"
                        >
                            Não
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDelete;
