import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import api, { baseURL } from "../../../services/apiIdz";
import Alerts from '../../../components/Alerts';
import InputMask from 'react-input-mask';
import "./ViewVeiculo.scss";

import { useAuth } from '../../../context/useAuth';

function ViewCampraVeiculos({ show, closeModalView, veiculo }) {
    const { token } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertDescription, setAlertDescription] = useState('');
    const [alertType, setAlertType] = useState('success');

    const handleAlert = (title, description, type) => {
        setAlertTitle(title);
        setAlertDescription(description);
        setAlertType(type);
        setIsVisible(true);
        setTimeout(() => setIsVisible(false), 3000); // Oculta o alerta após 3 segundos
    };


    const [editableVeiculo, setEditableVeiculo] = useState({
        marca: veiculo?.marca || '',
        modelo: veiculo?.modelo || '',
        ano: veiculo?.ano || '',
        preco: veiculo?.preco || '',
        quilometragem: veiculo?.quilometragem || '',
        diferenciais: veiculo?.diferenciais || '',
        fotos: {
            frente: veiculo?.fotos?.frente || '',
            interior: veiculo?.fotos?.interior || '',
            lateral: veiculo?.fotos?.lateral || '',
            traseira: veiculo?.fotos?.traseira || '',
        },
        nome: veiculo?.nome || '',
        email: veiculo?.email || '',
        telefone: veiculo?.telefone || '',
        cpf: veiculo?.cpf || '',
        endereco: {
            endereco: veiculo?.endereco?.endereco || '',
            bairro: veiculo?.endereco?.bairro || '',
            complemento: veiculo?.endereco?.complemento || '',
            cidade: veiculo?.endereco?.cidade || '',
            estado: veiculo?.endereco?.estado || '',
        },
        contrato: {
            valor: veiculo?.contrato?.valor || '',
            porcentagem: veiculo?.contrato?.porcentagem || '',
            multa: veiculo?.contrato?.multa || '',
        }
    });

    useEffect(() => {
        if (show) {
            setShowModal(true);
            setEditableVeiculo({
                ...editableVeiculo,
                ...veiculo,
                fotos: {
                    frente: veiculo?.imagem_frente ? `${baseURL}${veiculo.imagem_frente}` : '',
                    interior: veiculo?.imagem_interior ? `${baseURL}${veiculo.imagem_interior}` : '',
                    lateral: veiculo?.imagem_lateral ? `${baseURL}${veiculo.imagem_lateral}` : '',
                    traseira: veiculo?.imagem_traseira ? `${baseURL}${veiculo.imagem_traseira}` : '',
                }
            });
        }
    }, [show, veiculo]);

    const handleClose = () => {
        setShowModal(false);
        closeModalView();
    };

    const handleInputChange = (field, value) => {
        setEditableVeiculo((prevVeiculo) => ({
            ...prevVeiculo,
            [field]: value,
        }));
    };

    const handleNestedInputChange = (section, field, value) => {
        setEditableVeiculo((prevVeiculo) => ({
            ...prevVeiculo,
            [section]: {
                ...prevVeiculo[section],
                [field]: value,
            },
        }));
    };

    const handleSave = async () => {
        try {
            // Cria uma cópia do `editableVeiculo` e ajusta o JSON de acordo com o modelo esperado
            const dataToUpdate = {
                marca: veiculo.marca_id,
                modelo: veiculo.modelo_id,
                ano: editableVeiculo.ano,
                preco: editableVeiculo.preco,
                quilometragem: editableVeiculo.quilometragem,
                diferenciais: editableVeiculo.diferenciais,
                nome: editableVeiculo.nome,
                email: editableVeiculo.email,
                telefone: editableVeiculo.telefone,
                cpf: editableVeiculo.cpf,
                endereco: {
                    endereco: editableVeiculo.endereco.endereco,
                    bairro: editableVeiculo.endereco.bairro,
                    complemento: editableVeiculo.endereco.complemento,
                    cidade: editableVeiculo.endereco.cidade,
                    estado: editableVeiculo.endereco.estado,
                },
                contrato: {
                    valor: editableVeiculo.contrato.valor,
                    porcentagem: editableVeiculo.contrato.porcentagem.replace('%', ''),
                    multa: editableVeiculo.contrato.multa,
                }
            };
            
            const response = await api.put(`/api/vendaseucarro/${editableVeiculo.id}`, dataToUpdate, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });
            
            console.log("Resposta do servidor:", response); // Exibe a resposta completa no console
    
            handleAlert("Sucesso", "Dados salvos com sucesso!", "success");
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar dados:", error);
    
            // Exibir detalhes do erro na resposta do servidor, se disponível
            const errorMessage = error.response && error.response.data 
                ? JSON.stringify(error.response.data, null, 2) 
                : "Erro ao salvar dados.";
    
            handleAlert("Erro", errorMessage, "danger");
        }
    };
    
    return (
        <>
            {isVisible && (
                <Alerts 
                    title={alertTitle}
                    description={alertDescription}
                    type={alertType}
                />
            )}
            <Modal show={showModal} className="ModalLeadViewCampraVeiculos" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><Icon icon="material-symbols:dashboard-customize" /> Dados do Veículo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            {/* Dados do Veículo */}
                            <Col xs={12} sm={12}>
                                <div className='boxModalLead'>
                                    <h4>Dados do Veículo</h4>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Marca</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.marca} 
                                                    onChange={(e) => handleInputChange("marca", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Modelo</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.modelo} 
                                                    onChange={(e) => handleInputChange("modelo", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Ano</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.ano} 
                                                    onChange={(e) => handleInputChange("ano", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Preço</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.preco} 
                                                    onChange={(e) => handleInputChange("preco", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Quilometragem</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.quilometragem} 
                                                    onChange={(e) => handleInputChange("quilometragem", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Diferenciais</Form.Label>
                                                <Form.Control 
                                                    as="textarea" 
                                                    rows={3} 
                                                    value={editableVeiculo.diferenciais} 
                                                    onChange={(e) => handleInputChange("diferenciais", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            {/* Seção de Fotos com Background Cover */}
                                <Col xs={12}>
                                    <div className='boxModalLead'>
                                        <h4>Fotos do Veículo</h4>
                                        <ul>
                                            {Object.entries(editableVeiculo.fotos).map(([key, url]) => (
                                                <li 
                                                    xs={6} md={3} key={key} className="mb-3"
                                                    style={{
                                                        backgroundImage: `url(${url || ''})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        height: '150px',
                                                        cursor: url ? 'pointer' : 'default',
                                                        border: '1px solid #ddd'
                                                    }}
                                                
                                                >
                                                
                                                    {!url && <p>Imagem não disponível</p>}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>

                            {/* Dados de Contato */}
                            <Col xs={12} sm={12}>
                                <div className='boxModalLead'>
                                    <h4>Dados de Contato</h4>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.nome} 
                                                    onChange={(e) => handleInputChange("nome", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.email} 
                                                    onChange={(e) => handleInputChange("email", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Telefone</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.telefone} 
                                                    onChange={(e) => handleInputChange("telefone", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>CPF</Form.Label>
                                                <InputMask
                                                    mask="999.999.999-99"
                                                    value={editableVeiculo.cpf}
                                                    onChange={(e) => handleInputChange("cpf", e.target.value)}
                                                    maskChar=""
                                                    placeholder="000.000.000-00"
                                                >
                                                    {(inputProps) => <Form.Control {...inputProps} />}
                                                </InputMask>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                            {/* Endereço */}
                            <Col xs={12}>
                                <div className='boxModalLead'>
                                    <h4>Endereço</h4>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Endereço</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.endereco.endereco} 
                                                    onChange={(e) => handleNestedInputChange("endereco", "endereco", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Bairro</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.endereco.bairro} 
                                                    onChange={(e) => handleNestedInputChange("endereco", "bairro", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Cidade</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.endereco.cidade} 
                                                    onChange={(e) => handleNestedInputChange("endereco", "cidade", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Estado</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={editableVeiculo.endereco.estado} 
                                                    onChange={(e) => handleNestedInputChange("endereco", "estado", e.target.value)} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            {/* Dados Contratuais */}
                            <Col xs={12}>
                                <div className='boxModalLead'>
                                    <h4>Dados Contratuais</h4>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Valor</Form.Label>
                                                <InputMask
                                                    mask="R$ 999.999,99"
                                                    value={editableVeiculo.contrato.valor}
                                                    onChange={(e) => handleNestedInputChange("contrato", "valor", e.target.value)}
                                                    maskChar=""
                                                    placeholder="R$ 0,00"
                                                >
                                                    {(inputProps) => <Form.Control {...inputProps} />}
                                                </InputMask>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Porcentagem</Form.Label>
                                                <InputMask
                                                    mask="99%"
                                                    value={editableVeiculo.contrato.porcentagem}
                                                    onChange={(e) => handleNestedInputChange("contrato", "porcentagem", e.target.value)}
                                                >
                                                    {(inputProps) => <Form.Control {...inputProps} />}
                                                </InputMask>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Multa</Form.Label>
                                                <InputMask
                                                    mask="R$ 999.999,99"
                                                    value={editableVeiculo.contrato.multa}
                                                    onChange={(e) => handleNestedInputChange("contrato", "multa", e.target.value)}
                                                    maskChar=""
                                                    placeholder="R$ 0,00"
                                                >
                                                    {(inputProps) => <Form.Control {...inputProps} />}
                                                </InputMask>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col xs={12} className="text-center">
                                <Button className="Btns ButtonPrimary me-2" variant="primary" onClick={handleSave}>Salvar</Button>
                            </Col>
                            <Col xs={12} className="text-center">
                                <Button variant="Btns ButtonLight me-2" onClick={handleClose}>Fechar</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewCampraVeiculos;
