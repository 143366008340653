import React, { useState, useEffect } from 'react';
import { Col } from "react-bootstrap";
import { Icon } from '@iconify/react';
import api from '../../../services/apiIdz';
import { Tooltip as ReactTooltip } from "react-tooltip";

import './itemDestaque.scss';
import { useNavigate } from 'react-router';

function ItemDestaque({ onDelete, destaqueInfo, token, handleAlert, onEdit }) {

    const urlApi = `https://hdream.idzcar.com.br`
    const navigate = useNavigate()
    const [isPlaying, setIsPlaying] = useState(destaqueInfo.status == "Ativo" ? true : false);
    const [tooltipId, setTooltipId] = useState("my-tooltip-play");


    const togglePlayPause = async () => {
        setIsPlaying(!isPlaying);
        try {
            const status = !isPlaying ? 'Ativo' : 'Inativo';
            console.log({ status: status })
            await api.put(`/api/destaques/${destaqueInfo.id}`, { status: status }, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    'Token': token
                }
            }).then(res => {
                if (res.data) {
                    handleAlert("Sucesso", `O status do destaque ${destaqueInfo.id} foi atualizado para '${status}' com sucesso.`, "success")
                }
            });
        } catch (error) {
            handleAlert("Error", error, "danger")
        }
    };

    const handleDeleteClick = () => {
        console.log("delete id in icon", destaqueInfo?.id)
        onDelete(destaqueInfo?.id);
    };

    const handleEditClick = ()=>{
        console.log("edit id in icon", destaqueInfo)
        onEdit(destaqueInfo)
    }

    return (
        <>
            <Col xs={6} sm={12} md={3} lg={3}>
                <div className='ItemDestaque'>
                    <div className='imagem' style={destaqueInfo.imagem ? { background: `url(${urlApi + destaqueInfo.imagem})  no-repeat center / cover` } : null}>
                        {isPlaying ? (<span><i></i> ATIVO</span>) : (<span className='warning'><i></i> Pausado</span>)}
                    </div>
                    <h2>{destaqueInfo?.modelo}</h2>

                    <h6>{destaqueInfo?.titulo}</h6>

                    {/* <button className='Btns ButtonPrimary' onClick={() => { navigate(`/banners/editar/${destaqueInfo.id}`) }}>Editar</button> */}
                    <div className='acoes'>
                        <button data-tooltip-id="" className='Btns ButtonPrimary' onClick={handleEditClick}>Editar</button>

                        <button data-tooltip-id="my-tooltip-delete" className='Btns ButtonIcon' onClick={handleDeleteClick}><i><Icon icon="mdi:trash"></Icon></i></button>
                        <button data-tooltip-id={tooltipId} onClick={togglePlayPause} className='Btns ButtonIcon'>
                            {isPlaying ? (
                                <i><Icon icon="material-symbols:pause"></Icon></i>
                            ) : (
                                <i><Icon icon="ph:play-fill"></Icon></i>
                            )}
                        </button>
                    </div>
                </div>
            </Col>
            <ReactTooltip
                id={tooltipId}
                place="bottom"
                content={isPlaying ? "Pausar banner" : "Ativar banner"}
                style={{ backgroundColor: "#FFFFFF", color: "#000000" }}
            ></ReactTooltip>
        </>
    );
}

export default ItemDestaque;


