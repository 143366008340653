import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Form } from "react-bootstrap";

function ModalSaveModel({ show, handleClose, onSave, handleAlert }) {
    const [nomeModelo, setNomeModelo] = useState("");
    const inputRef = useRef();

    useEffect(() => {
        if (show) {
            setNomeModelo(""); // Reseta o nome do modelo ao abrir o modal
            setTimeout(() => inputRef.current?.focus(), 100); // Foco no input após renderização
        }
    }, [show]);

    const handleSave = () => {
        if (!nomeModelo.trim()) {
            handleAlert("Erro", "Por favor, insira um nome para o modelo.", "danger");
            return;
        }

        if (typeof onSave === "function") {
            onSave(nomeModelo); // Passa o nome do modelo para o componente pai
            handleAlert("Sucesso", "Modelo salvo com sucesso!", "success");
        } else {
            console.error("A função onSave não foi definida ou não é uma função.");
        }

        setNomeModelo(""); // Reseta o campo
        handleClose(); // Fecha o modal
    };

    const handleCancel = () => {
        setNomeModelo(""); // Reseta o campo ao cancelar
        handleClose(); // Fecha o modal
    };

    return (
        <Modal
            show={show}
            onHide={handleCancel}
            aria-labelledby="modal-save-model-title"
            centered // Centraliza o modal
        >
            <Modal.Header closeButton>
                <Modal.Title id="modal-save-model-title">Salvar como Modelo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label htmlFor="nome-modelo">Nome do Modelo</Form.Label>
                        <Form.Control
                            id="nome-modelo"
                            ref={inputRef}
                            type="text"
                            placeholder="Insira o nome do modelo"
                            value={nomeModelo}
                            onChange={(e) => setNomeModelo(e.target.value)}
                            className="Inputs"
                        />
                    </Form.Group>
                    <Form.Group className="text-center mt-4">
                        <Button
                            variant="primary"
                            onClick={handleSave}
                            className="Btns ButtonPrimaryLight"
                        >
                            Salvar
                        </Button>
                        <Button
                            onClick={handleCancel}
                            variant="secondary"
                            className="Btns ButtonSecondaryLight"
                        >
                            Cancelar
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ModalSaveModel;
