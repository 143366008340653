import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import InputMask from 'react-input-mask';
import ReactQuill from 'react-quill';
import { useMediaQuery } from 'react-responsive';
import Navbar from "../../../components/Navbar";
import Alerts from '../../../components/Alerts';
import ModalSaveModel from "../../../components/Contrato/ModalSaveModel";
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';
import './Novo.scss';
import 'react-quill/dist/quill.snow.css';

function Contratos() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const { token } = useAuth();
    const { id } = useParams(); // Captura o ID da URL
    const navigate = useNavigate();

    const [menu, setMenu] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const [currentSection, setCurrentSection] = useState('informacoes'); // Corrigido
    const [comprador, setComprador] = useState({});
    const [dadosBancarios, setDadosBancarios] = useState({});
    const [veiculo, setVeiculo] = useState({});
    const [clausulas, setClausulas] = useState([{ secao: 1, clausula: "", descricao: "" }]);
    const [showSaveModel, setShowSaveModel] = useState(false);
    const [tipoContrato, setTipoContrato] = useState(null);
    const [forceCreateNew, setForceCreateNew] = useState(false);

    const [vendedor, setVendedor] = useState({
        razao_social: "TG18 Serviços de Blindagem Ltda",
        cnpj: "21.313.980/0001-52",
        cep: "04444-000",
        logradouro: "Av. Miguel Yunes",
        numero: "823 Letra B, Galpão 2",
        complemento: "",
        bairro: "Usina Piratininga",
        cidade: "São Paulo",
        estado: "SP",
        representante: "Daisy Maria Roberto Pereira",
        identidade: "9.470.015-1 SSP/SP",
        inscricao_estadual: "123.346.526.119",
    });

    useEffect(() => {
        if (id) {
            console.log('id', id)
            const fetchContrato = async () => {
                try {
                    const response = await api.get(`/api/contrato/${id}`, {
                        headers: {
                            'Authorization': process.env.REACT_APP_API_KEY,
                            "Token": token,
                        },
                    });
                    if (response.status === 200) {
                        const contrato = response.data;
    
                        // Preencher os estados com os dados do contrato
                        setComprador({
                            nome: contrato.comprador.nome,
                            doc: contrato.comprador.doc,
                            cep: contrato.comprador.cep,
                            logradouro: contrato.comprador.logradouro,
                            numero: contrato.comprador.numero,
                            complemento: contrato.comprador.complemento,
                            bairro: contrato.comprador.bairro,
                            cidade: contrato.comprador.cidade,
                            estado: contrato.comprador.estado,
                        });
    
                        setVendedor({
                            razao_social: contrato.vendedor.razao_social,
                            cnpj: contrato.vendedor.cnpj,
                            cep: contrato.vendedor.cep,
                            logradouro: contrato.vendedor.logradouro,
                            numero: contrato.vendedor.numero,
                            complemento: contrato.vendedor.complemento,
                            bairro: contrato.vendedor.bairro,
                            cidade: contrato.vendedor.cidade,
                            estado: contrato.vendedor.estado,
                        });
    
                        setDadosBancarios({
                            banco: contrato.dados_bancario.banco,
                            agencia: contrato.dados_bancario.agencia,
                            cc: contrato.dados_bancario.cc,
                            doc: contrato.dados_bancario.doc,
                            titular: contrato.dados_bancario.titular,
                        });
    
                        setVeiculo({
                            modelo: contrato.veiculo.modelo,
                            ano: contrato.veiculo.ano,
                            placa: contrato.veiculo.placa,
                            chassi: contrato.veiculo.chassi,
                            valor_total: contrato.veiculo.valor_total,
                            sinal_pago: contrato.veiculo.sinal_pago,
                        });
    
                        setClausulas(contrato.secao.map((secao, index) => ({
                            secao: index + 1,
                            clausula: secao.clausula,
                            descricao: secao.descricao,
                        })));

                        setTipoContrato(contrato.tipo);
                    }
                } catch (error) {
                    console.error("Erro ao buscar os dados do contrato:", error);
                    handleAlert("Erro", "Não foi possível carregar os dados do contrato.", "danger");
                }
            };
    
            fetchContrato();
        }
    }, [id]);
    

    // Alerta
    const handleAlert = (title, description, type) => {
        setIsVisible(true);
        setTitle(title);
        setDescription(description);
        setType(type);
    };

    // Adicionar e remover cláusulas
    const addClausula = () => {
        setClausulas([...clausulas, { secao: clausulas.length + 1, clausula: "", descricao: "" }]);
    };

    const removeClausula = (index) => {
        const updatedClausulas = clausulas.filter((_, i) => i !== index);
        setClausulas(updatedClausulas);
    };

    // Carregar dados do contrato para edição
    useEffect(() => {
        if (id) {
            const fetchContrato = async () => {
                try {
                    const response = await api.get(`/api/contrato/${id}`, {
                        headers: {
                            'Authorization': process.env.REACT_APP_API_KEY,
                            "Token": token,
                        },
                    });
                    console.log("data", response.data);
                    if (response.status === 200) {
                        const contratoData = response.data;
                        
                        setComprador(contratoData.comprador || {});
                        setVendedor(contratoData.vendedor || {});
                        setDadosBancarios(contratoData.dados_bancario || {});
                        setVeiculo(contratoData.veiculo || {});
                        setClausulas(
                            contratoData.secao?.map((secao) => ({
                                clausula: secao.clausula || "",
                                descricao: secao.descricao || "",
                            })) || []
                        );
                    }
                } catch (error) {
                    console.error("Erro ao carregar o contrato:", error.response?.data || error.message);
                    handleAlert("Erro", "Falha ao carregar o contrato.", "danger");
                }
            };
            fetchContrato();
        }
    }, [id]);

    const handleSubmit = async (isModel = false, nomeModelo = "", forceCreateNew = false) => {
        const vendedorPayload = { ...vendedor };
        if (!vendedor.complemento) {
            delete vendedorPayload.complemento;
        }
    
        const today = new Date();
        const dataCriacao = today.toISOString().split("T")[0];
        const dataVencimento = new Date(today.setDate(today.getDate() + 15)).toISOString().split("T")[0];
    
        const payload = {
            data_criacao: dataCriacao,
            data_vencimento: dataVencimento,
            vendedor: vendedorPayload,
            secao: clausulas.map((clausula, index) => ({
                secao: index + 1, // Adicionando o número da seção
                clausula: clausula.clausula,
                descricao: clausula.descricao,
            })),
            tipo: tipoContrato !== null ? tipoContrato : 1,
            titulo: isModel ? nomeModelo : "",
        };
    
        if (comprador.nome || comprador.doc || comprador.cep || comprador.logradouro || comprador.numero) {
            payload.comprador = comprador;
        }
    
        if (veiculo.modelo || veiculo.ano || veiculo.placa || veiculo.chassi) {
            payload.veiculo = veiculo;
        }
    
        if (dadosBancarios.banco || dadosBancarios.agencia || dadosBancarios.cc || dadosBancarios.doc) {
            payload.dados_bancario = dadosBancarios;
        }
    
        try {
            if (forceCreateNew) {
                // POST para criar um novo contrato (ignorar id)
                const response = await api.post('/api/contrato', payload, {
                    headers: {
                        'Authorization': process.env.REACT_APP_API_KEY,
                        "Token": token,
                    },
                });
                if (response.status === 200) {
                    handleAlert("Sucesso", "Novo contrato criado com êxito.", "success");
                    setTimeout(() => {
                        navigate("/contratos");
                    }, 3000);
                }
            } else if (id) {
                // PUT para atualizar contrato existente
                const response = await api.put(`/api/contrato/${id}`, payload, {
                    headers: {
                        'Authorization': process.env.REACT_APP_API_KEY,
                        "Token": token,
                    },
                });
                if (response.status === 200) {
                    handleAlert("Sucesso", "Contrato atualizado com êxito.", "success");
                    setTimeout(() => {
                        navigate("/contratos");
                    }, 3000);
                }
            } else {
                // POST para criar um novo contrato ou modelo
                const response = await api.post('/api/contrato', payload, {
                    headers: {
                        'Authorization': process.env.REACT_APP_API_KEY,
                        "Token": token,
                    },
                });
                if (response.status === 200) {
                    handleAlert("Sucesso", "Novo contrato criado com êxito.", "success");
                    setTimeout(() => {
                        navigate("/contratos");
                    }, 3000);
                }
            }
        } catch (error) {
            console.error("Erro ao salvar o contrato:", error.response?.data || error.message);
            handleAlert("Erro", "Falha ao salvar o contrato.", "danger");
        }
    };
    

    const modules = {
        toolbar: [
            ['bold', 'italic'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ],
    };

    return (
        <>
            <Navbar menu={menu} handleMenuToggle={() => setMenu(!menu)} />
            {isVisible && <Alerts title={isTitle} description={initDescription} type={initType} />}

            <div className="Content Contratos">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            {isMobile ? (
                                <div className='HeaderDashboard'>
                                    <h2>{id ? "Editar Contrato" : "Novo Contrato"}</h2>
                                </div>
                            ) : (
                                <div className='HeaderLeads'>
                                    <i><Icon icon="fa-solid:car" /></i>
                                    <h2>{id ? "Editar Contrato" : "Novo Contrato"}<br /><span>Organize e crie seus contratos</span></h2>
                                </div>
                            )}
                        </Col>
                    </Row>
                    
                    {/* Navegação */}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='NavVeiculos'>
                                <ul>
                                    <li>
                                        <a
                                            className={currentSection === 'informacoes' ? 'active' : ''}
                                            onClick={() => setCurrentSection('informacoes')}
                                        >
                                            <i><Icon className="icons" icon="material-symbols:info" /></i>
                                            <span>INFORMAÇÕES<br />&nbsp;</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className={currentSection === 'clausulas' ? 'active' : ''}
                                            onClick={() => setCurrentSection('clausulas')}
                                        >
                                            <i><Icon className="icons" icon="solar:chat-round-money-bold" /></i>
                                            <span>CLÁUSULAS<br />&nbsp;</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                        {/* Seção de informações */}
                        {currentSection === 'informacoes' && (
                            <div className='Novo'>
                                <Row className='justify-content-center'>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className='HeaderNovo'>
                                            <h2>Dados do Contratante</h2>
                                            <div className='statusMultiplos'></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <Form.Group className="FormNovo">
                                            <Row>
                                                {/* Nome */}
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Nome</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Nome"
                                                                value={comprador.nome || ""}
                                                                onChange={(e) => setComprador({ ...comprador, nome: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                {/* CPF/CNPJ */}
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>CPF/CNPJ</span>
                                                        <div>
                                                            <InputMask
                                                                mask="99.999.999/9999-99"
                                                                maskChar=""
                                                                alwaysShowMask={false}
                                                                placeholder="CPF ou CNPJ"
                                                                value={comprador.doc || ""}
                                                                onChange={(e) => setComprador({ ...comprador, doc: e.target.value })}
                                                                className='Inputs form-control'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                {/* CEP */}
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>CEP</span>
                                                        <div>
                                                            <InputMask
                                                                mask="99999-999"
                                                                placeholder="CEP"
                                                                value={comprador.cep || ""}
                                                                onChange={(e) => setComprador({ ...comprador, cep: e.target.value })}
                                                                className='Inputs form-control'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                {/* Logradouro */}
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Logradouro</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Logradouro"
                                                                value={comprador.logradouro || ""}
                                                                onChange={(e) => setComprador({ ...comprador, logradouro: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                {/* Número */}
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Número</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Número"
                                                                value={comprador.numero || ""}
                                                                onChange={(e) => setComprador({ ...comprador, numero: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                {/* Complemento */}
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Complemento</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Complemento"
                                                                value={comprador.complemento || ""}
                                                                onChange={(e) => setComprador({ ...comprador, complemento: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                {/* Bairro */}
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Bairro</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Bairro"
                                                                value={comprador.bairro || ""}
                                                                onChange={(e) => setComprador({ ...comprador, bairro: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                {/* Cidade */}
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Cidade</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Cidade"
                                                                value={comprador.cidade || ""}
                                                                onChange={(e) => setComprador({ ...comprador, cidade: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                {/* Estado */}
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Estado</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Estado"
                                                                value={comprador.estado || ""}
                                                                onChange={(e) => setComprador({ ...comprador, estado: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
        
                                <Row className='justify-content-center'>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className='HeaderNovo'>
                                            <h2>Dados do Contratada</h2>
                                            <div className='statusMultiplos'></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <Form.Group className="FormNovo">
                                            <Row>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Razão Social</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Razão Social"
                                                                value={vendedor.razao_social || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, razao_social: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>CNPJ</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="CNPJ"
                                                                value={vendedor.cnpj || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, cnpj: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>CEP</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="CEP"
                                                                value={vendedor.cep || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, cep: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Logradouro</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Logradouro"
                                                                value={vendedor.logradouro || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, logradouro: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Número</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Número"
                                                                value={vendedor.numero || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, numero: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Complemento</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Complemento"
                                                                value={vendedor.complemento || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, complemento: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Bairro</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Bairro"
                                                                value={vendedor.bairro || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, bairro: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Cidade</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Cidade"
                                                                value={vendedor.cidade || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, cidade: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Estado</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Estado"
                                                                value={vendedor.estado || ""}
                                                                onChange={(e) => setVendedor({ ...vendedor, estado: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
            
                                <Row className='justify-content-center'>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className='HeaderNovo'>
                                            <h2>Dados Bancários</h2>
                                            <div className='statusMultiplos'></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <Form.Group className="FormNovo">
                                            <Row>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Banco</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Banco"
                                                                value={dadosBancarios.banco || ""}
                                                                onChange={(e) => setDadosBancarios({ ...dadosBancarios, banco: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Agência</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Agência"
                                                                value={dadosBancarios.agencia || ""}
                                                                onChange={(e) => setDadosBancarios({ ...dadosBancarios, agencia: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Conta Corrente</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Conta Corrente"
                                                                value={dadosBancarios.cc || ""}
                                                                onChange={(e) => setDadosBancarios({ ...dadosBancarios, cc: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>CPF/CNPJ do Titular</span>
                                                        <div>
                                                            <InputMask
                                                                mask="99.999.999/9999-99"
                                                                placeholder="CPF/CNPJ"
                                                                value={dadosBancarios.doc || ""}
                                                                onChange={(e) => setDadosBancarios({ ...dadosBancarios, doc: e.target.value })}
                                                                className='Inputs form-control'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <span>Nome do Titular</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Nome do Titular"
                                                                value={dadosBancarios.titular || ""}
                                                                onChange={(e) => setDadosBancarios({ ...dadosBancarios, titular: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
        
                                <Row className='justify-content-center'>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className='HeaderNovo'>
                                            <h2>Dados do Veículo</h2>
                                            <div className='statusMultiplos'></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <Form.Group className="FormNovo">
                                            <Row>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Modelo</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Modelo"
                                                                value={veiculo.modelo || ""}
                                                                onChange={(e) => setVeiculo({ ...veiculo, modelo: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Ano</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Ano"
                                                                value={veiculo.ano || ""}
                                                                onChange={(e) => setVeiculo({ ...veiculo, ano: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Placa</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Placa"
                                                                value={veiculo.placa || ""}
                                                                onChange={(e) => setVeiculo({ ...veiculo, placa: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Chassi</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Chassi"
                                                                value={veiculo.chassi || ""}
                                                                onChange={(e) => setVeiculo({ ...veiculo, chassi: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Valor Total</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Valor Total"
                                                                value={veiculo.valor_total || ""}
                                                                onChange={(e) => setVeiculo({ ...veiculo, valor_total: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <span>Sinal Pago</span>
                                                        <div>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Sinal Pago"
                                                                value={veiculo.sinal_pago || ""}
                                                                onChange={(e) => setVeiculo({ ...veiculo, sinal_pago: e.target.value })}
                                                                className='Inputs'
                                                            />
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                        <Form.Group className="FormNovo">
                                                <Button className='Btns ButtonPrimaryLight' onClick={() => setCurrentSection('clausulas')}>Continuar</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        </Col>
                    </Row>
                    {/* Seção de cláusulas */}
                    {currentSection === 'clausulas' && (
                        <div className='Novo'>
                            <Row className='justify-content-center'>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <div className='HeaderNovo'>
                                        <h2>Cláusulas do Contrato</h2>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <Form.Group className="FormNovo">
                                        <Row>
                                            {clausulas.map((clausula, index) => (
                                                <React.Fragment key={index}>
                                                    <Col xs={12} className="mb-4">
                                                        <Form.Label className="Label">
                                                            <span className="tituloClausula">Cláusula {index + 1}</span>
                                                            {index === 0 && (
                                                                <Button className="Btns Add" onClick={addClausula}>
                                                                    <Icon icon="ic:baseline-plus" />
                                                                </Button>
                                                            )}
                                                            <div className="FormControl">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Nome da Cláusula"
                                                                    value={clausula.clausula || ""}
                                                                    onChange={(e) => {
                                                                        const updatedClausulas = [...clausulas];
                                                                        updatedClausulas[index].clausula = e.target.value;
                                                                        setClausulas(updatedClausulas);
                                                                    }}
                                                                    className="Inputs"
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                        <Form.Label className="Label">
                                                            <span>Descrição</span>
                                                            <div className="FormControl">
                                                                <ReactQuill
                                                                    value={clausula.descricao}
                                                                    onChange={(value) => {
                                                                        const updatedClausulas = [...clausulas];
                                                                        updatedClausulas[index].descricao = value;
                                                                        setClausulas(updatedClausulas);
                                                                    }}
                                                                    modules={modules}
                                                                    className="QuillEditor"
                                                                    placeholder="Adicione a descrição da cláusula"
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} className="btns-plus">
                                                        {index > 0 && (
                                                            <Button
                                                                className="Btns Remove"
                                                                onClick={() => removeClausula(index)}
                                                            >
                                                                <Icon icon="ic:baseline-minus" />
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </React.Fragment>
                                            ))}
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='justify-content-center'> 
                                <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                    <Button
                                        className='Btns ButtonPrimaryLight'
                                        variant="success"
                                        onClick={() => handleSubmit(false)} // Para contratos normais
                                    >
                                        {id ? "Atualizar" : "Salvar"}
                                    </Button>
                                    <Button
                                        className='Btns ButtonSecondary'
                                        variant="success"
                                        onClick={() => setShowSaveModel(true)} // Salvar como Modelo
                                    >
                                        Salvar como Modelo
                                    </Button>
                                    { id && (
                                        <Button
                                            className="Btns ButtonSecondaryLight"
                                            variant="success"
                                            onClick={() => handleSubmit(false, "", true)} // Força criação de novo contrato
                                        >
                                            Criar Novo
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    )}
                    
                    </Container>
                    <ModalSaveModel
                        show={showSaveModel}
                        handleClose={() => setShowSaveModel(false)}
                        onSave={(nomeModelo) => handleSubmit(true, nomeModelo)} 
                        handleAlert={handleAlert}
                    />
            </div>


        </>
    );
}

export default Contratos;