import axios from 'axios';

const isProduction = true;

export const baseURL = isProduction 
    ? process.env.REACT_APP_API_URL || "https://api.idzcar.com.br/"
    : process.env.REACT_APP_HML_URL || "https://homolog.idzcar.com.br/";

const api = axios.create({
    baseURL,
    headers: {
        "Authorization": process.env.REACT_APP_API_KEY
    }
});

export default api;