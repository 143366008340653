import React, { useState, useEffect } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import api from "../../../services/apiIdz";
import { useNavigate } from 'react-router-dom';

import "./ViewLead.scss";

function ViewLead({ show, currentLead, token, getLeads, closeModalView, setVisualizado }) {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [trackerData, setTrackerData] = useState([]);
    const [proposta, setProposta] = useState({ pedido: '', produtos: [], vl_final: 'R$ 0,00' });
    const [currentPage, setCurrentPage] = useState('PÁGINAS');
    const [numPages, setNumPages] = useState(0);
    const [numBanners, setNumBanners] = useState(0);
    const [numServicos, setNumServicos] = useState(0);

    // Tratamento da origem
    const getOrigem = (url) => {
        if (!url) return "Origem desconhecida";

        try {
            const urlParams = new URLSearchParams(url.split('?')[1]);
            if (urlParams.has("utm_source")) {
                return urlParams.get("utm_source");
            }
            if (url.includes("1021motors.com.br")) {
                return "Direto";
            }
        } catch (error) {
            console.error("Erro ao analisar a URL:", error);
        }

        return url;
    };

    const inferPagesFromUrl = (url) => {
        console.log("Inferindo páginas da URL:", url);
    
        if (!url || !url.includes("1021motors.com.br")) {
            console.log("URL inválida ou fora do domínio:", url);
            return "Nenhuma página acessada";
        }
    
        const path = url.split("1021motors.com.br")[1];
        if (!path) {
            console.log("Caminho vazio, retornando 'Página inicial'");
            return "Página inicial";
        }
    
        const cleanPath = path.split("?")[0]; // Remove query parameters
        const formattedPath = cleanPath
            .split("/") // Divide por "/"
            .filter((segment) => segment) // Remove partes vazias
            .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1)) // Capitaliza
            .join(" > "); // Junta com " > "
    
        console.log("Caminho formatado:", formattedPath);
        return formattedPath;
    };

    // Efeito para abrir o modal e carregar os dados
    useEffect(() => {
        if (show && currentLead?.id) {
            setShowModal(true);
            getTracker(currentLead.id);

            if (currentLead.tipo === 3) {
                getPropostaEstetica(currentLead.id);
            } else {
                getProposta(currentLead.id);
            }

            if (currentLead.status < 1) {
                setVisualizado(currentLead.id);
            }
        }
    }, [show, currentLead]);

    useEffect(() => {
        const uniquePages = [];
    
        trackerData.forEach((item) => {
            console.log("Processando item:", item);
    
            let pages = [];
    
            // Verificar se o campo `paginas` tem dados válidos
            if (item.paginas) {
                try {
                    pages = typeof item.paginas === "string" ? JSON.parse(item.paginas) : item.paginas;
                    console.log("Páginas encontradas (direto):", pages);
                } catch (error) {
                    console.error("Erro ao parsear páginas:", item.paginas, error);
                    pages = [];
                }
            }
    
            // Fallback: inferir páginas da URL de origem se `paginas` estiver vazio
            if ((!item.paginas || pages.length === 0) && item.origem?.includes("1021motors.com.br")) {
                const inferredPage = inferPagesFromUrl(item.origem);
                pages = [inferredPage];
                console.log("Páginas inferidas da URL:", inferredPage);
            }
    
            // Adicionar páginas únicas à lista
            pages.forEach((page) => {
                const trimmedPage = page.trim();
                if (!uniquePages.includes(trimmedPage)) {
                    uniquePages.push(trimmedPage);
                    console.log("Página adicionada:", trimmedPage);
                } else {
                    console.log("Página já existente:", trimmedPage);
                }
            });
        });
    
        console.log("Páginas únicas finais:", uniquePages);
    
        // Atualizar estados
        setNumPages(uniquePages.length);
        setNumBanners(trackerData[0]?.banners ? 1 : 0);
        setNumServicos(0);
    }, [trackerData]);
    

    const getTracker = async (id) => {
        try {
            const res = await api.get(`/api/navigation-tracker?lead=${id}`, {
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                },
            });
            setTrackerData(res.data);
        } catch (error) {
            console.error("Erro ao buscar dados de navegação:", error);
        }
    };

    const getProposta = async (id) => {
        try {
            const response = await api.get(`/api/orcamento-blindagem?lead=${id}&two_factor=1021`);
            if (response.data.length > 0) {
                const data = response.data[0];
                setProposta(data.orcamento || {});
            }
        } catch (error) {
            console.error("Erro ao buscar proposta:", error);
        }
    };

    const getPropostaEstetica = async (id) => {
        try {
            const response = await api.get(`/api/orcamento-estetica?lead=${id}&two_factor=1021`);
            const data = response.data.orcamento;

            if (data) {
                const marcas = data.marcas?.[3] || {};
                setProposta({
                    marca: data.marca,
                    modelo: data.modelo,
                    imagem: data.imagem,
                    produtos: marcas.produtos || [],
                    vl_final: marcas.vl_final || 'Não informado',
                });
            }
        } catch (error) {
            console.error("Erro ao buscar proposta estética:", error);
        }
    };

    const formatNumber = (number) => (number < 10 ? `0${number}` : number);

    const handleClose = () => {
        setShowModal(false);
        closeModalView();
    };

    const handleGerarProposta = () => {
        const dadosProposta = {
            nome: currentLead.nome,
            email: currentLead.email,
            telefone: currentLead.telefone,
            marcaId: proposta.marcaId,
            modeloId: proposta.modeloId,
            produtos: proposta.produtos,
            vl_final: proposta.vl_final,
        };

        navigate('/proposta/novo', { state: { dadosProposta } });
    };

    return (
        <Modal show={showModal} className="ModalLead">
            <Modal.Header>
                <Modal.Title><Icon icon="material-symbols:dashboard-customize"></Icon> Você tem um novo Lead!<span>novo lead</span></Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <i className='flutu'><Icon icon="mdi:bell"></Icon></i>
                <Form>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <div className='boxModalLead'>
                            
                                <h4>Contato</h4>
                                {currentLead.tipo === 1  &&
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value={currentLead.veiculo_interesse} disabled />
                                </Form.Group>
                                }
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value={currentLead.nome} disabled />
                                </Form.Group>
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value={currentLead.email} disabled />
                                </Form.Group>
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value={currentLead.telefone} disabled />
                                </Form.Group>
                                {currentLead.tipo === 1 || currentLead.tipo === 2  &&
                                <Form.Group className="mb-1">
                                    <Form.Label></Form.Label>
                                    <textarea placeholder="Proposta:" disabled
                                        value={
                                            currentLead.tipo === 1
                                                ? currentLead.proposta
                                                : currentLead.tipo === 4
                                                    ? currentLead.assunto
                                                    : "Interesse em blindar " + proposta.marca + " " + proposta.modelo
                                        }
                                    ></textarea>

                                </Form.Group>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className='navegacao'>
                            <div className='boxModalLead'>
                                <h4>Navegação</h4>
                                <ul className="Monitoramento origem">
                                    <span>ORIGEM:</span>
                                    <ul>
                                        <li>
                                            {getOrigem(currentLead.origem)}
                                        </li>
                                    </ul>
                                </ul>
                                <Button className='btnFilter btnStart activo' onClick={() => setCurrentPage('PÁGINAS')}>
                                    PÁGINAS <span>{formatNumber(numPages)}</span>
                                </Button>
                                <Button className='btnFilter' onClick={() => setCurrentPage('BANNERS')}>
                                    BANNERS <span>{formatNumber(numBanners)}</span>
                                </Button>
                                <Button className='btnFilter btnEnd' onClick={() => setCurrentPage('SERVIÇOS')} disabled={numServicos === 0}>
                                    SERVIÇOS {numServicos === 0 ? null : <span>{formatNumber(numServicos)}</span>}
                                </Button>
                                {currentPage === 'PÁGINAS' && (
                                    <ul className="Monitoramento color">
                                        <span>Páginas Acessadas:</span>
                                        <ul>
                                            {trackerData.reduce((uniquePages, item) => {
                                                let pages = [];

                                                // Tentar usar `paginas` diretamente, senão inferir da `origem`
                                                if (item.paginas) {
                                                    try {
                                                        pages = typeof item.paginas === "string" ? JSON.parse(item.paginas) : item.paginas;
                                                    } catch (error) {
                                                        console.error("Erro ao parsear páginas:", item.paginas, error);
                                                        pages = [];
                                                    }
                                                } else if (item.origem) {
                                                    pages = [inferPagesFromUrl(item.origem)];
                                                }

                                                // Adicionar apenas páginas únicas
                                                pages.forEach((pagina) => {
                                                    const trimmedPagina = pagina.trim();
                                                    if (!uniquePages.includes(trimmedPagina)) {
                                                        uniquePages.push(trimmedPagina);
                                                    }
                                                });

                                                return uniquePages;
                                            }, []).flatMap((pagina, index, array) => {
                                                // Se o item contém " > ", divida em segmentos, senão trate como único
                                                const segments = pagina.includes(" > ") ? pagina.split(" > ") : [pagina];

                                                return segments.flatMap((segment, subIndex) => [
                                                    <li key={`${index}-${subIndex}`}>{segment}</li>,
                                                    subIndex < segments.length - 1 && (
                                                        <li className="setas" key={`seta-${index}-${subIndex}`}>
                                                            <Icon icon="mingcute:right-line" />
                                                        </li>
                                                    ),
                                                    // Caso seja o último segmento do array de páginas, adiciona separador entre páginas
                                                    subIndex === segments.length - 1 && index < array.length - 1 && (
                                                        <li className="setas" key={`inter-page-separator-${index}`}>
                                                            <Icon icon="mingcute:right-line" />
                                                        </li>
                                                    ),
                                                ]);
                                            })}
                                        </ul>
                                    </ul>
                                )}


                                {currentPage === 'BANNERS' && trackerData[0]?.banners && (
                                    <>
                                        {trackerData[0]?.banners && (
                                            <ul className="Monitoramento color">
                                                <span> BANNERS CLICADOS:</span>
                                                <ul>
                                                    <li>{trackerData[0].banners}</li><li className='setas'><Icon icon='mingcute:right-line' /></li>
                                                </ul>
                                            </ul>
                                        )}
                                    </>
                                )}
                                {currentPage === 'SERVIÇOS' && (

                                    <ul className="Monitoramento color">
                                        <span> Serviços Visitados:</span>
                                        <ul>
                                            <li></li><li className='setas'><Icon icon='mingcute:right-line' /></li>
                                        </ul>
                                    </ul>
                                )}
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            {currentLead.tipo === 1 &&
                                <>
                                    <div className='boxModalLead end'>
                                        <h4>Carro de entrada</h4>
                                        <Form.Group className="mb-2">
                                            <Row>
                                                <Col sm={4}>
                                                    <Form.Label>MODELO</Form.Label>
                                                    <Form.Control type="text" value={currentLead.veiculo_entrada} disabled />
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Label>KM</Form.Label>
                                                    <Form.Control type="text" value={currentLead.km} disabled />
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Label>ALGUNS DETALHES</Form.Label>
                                                    <Form.Control type="text" value={currentLead.detalhe} disabled />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </div>
                                </>
                            }
                            {currentLead.tipo === 2 &&
                                <>
                                    <div className='boxModalLead end'>
                                        <h4>interesse Blindagem</h4>
                                        <h2><span>Valor Final:</span>{proposta.vl_final}</h2>
                                        <ul className='boxResumo'>
                                            {proposta.produtos?.map((produto, index) => (
                                                <li key={index}>
                                                    <ul>
                                                        <li>{produto.produto}</li>
                                                        <li><span>{produto.preco}</span></li>
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            }
                            {currentLead.tipo === 3 && proposta && (
                                <>
                                    <div className="boxModalLead end">
                                        <h4>
                                            Interesse Estética - {`${proposta.marca || ''} ${proposta.modelo || ''}`}
                                        </h4>

                                        <h2>
                                            <span>Valor Final:</span> {proposta.vl_final || 'Não informado'}
                                        </h2>

                                        <ul className="boxResumo">
                                            {proposta.produtos.length > 0 ? (
                                                proposta.produtos.map((produto, index) => (
                                                    <li key={index}>
                                                        <ul>
                                                            <li>{produto.produto}</li>
                                                            <li><span>{produto.preco}</span></li>
                                                        </ul>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>Nenhum produto disponível.</li>
                                            )}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Group className="text-center">
                                <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                    Fechar
                                </Button>
                                <Button 
                                    variant="primary" 
                                    className="Btns ButtonPrimaryLight" 
                                    onClick={handleGerarProposta}  // Adiciona essa função
                                >
                                    Gerar Proposta
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ViewLead;