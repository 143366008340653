import React, { useState, useEffect } from 'react';
import api from "../../../services/apiIdz";
import { Icon } from '@iconify/react';

function App({ carInfo, onModelResponse, setLoadingStatus }) {
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    setLoadingStatus(true); // Define o status de loading como true antes de fazer a solicitação
    setError(null);

    try {
      const response = await api.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-3.5-turbo",
          messages: [
              { role: "system", content: `Por favor, faça um titulo, uma chamada, com uma descrição criativa e vendedora, lista com pelo menos 5 itens no interior e  lista com  pelo menos 5  itens no exterior do veiculo  ${carInfo}
          Devolva a resposta em json com o seguinte formato:
          { interior : ['item', 'item', 'item']
          chamada: 'chamada',
          titulo: 'titulo',
          descricao: 'texto',
          exterior: ['item', 'item', 'item']
          Retire qualquer caractere que não seja válido para um JSON!!!!
          }
          
          ?` },
              { role: "user", content: "Sim, por favor." }
          ],
            max_tokens: 2000,
            temperature: 0.7
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_OPENAI_KEY}`
          }
        }
      );

      console.log("Resposta da API recebida:", response);

      if (response.data.choices && response.data.choices.length > 0) {
        const modelResponse = response.data.choices[0].message.content.trim();
        onModelResponse(modelResponse);
      } else {
        console.error("Resposta da API não contém escolhas.");
        setError('Erro ao obter detalhes do carro: Resposta da API incompleta.');
      }
    } catch (error) {
      console.error("Erro ao obter detalhes do carro:", error);
      setError('Erro ao obter detalhes do carro.');
    } finally {
      setLoadingStatus(false); // Define o status de loading como false após a solicitação ser concluída
    }
  };
  

  return (
    <button onClick={handleSubmit} className='IA'>
      <Icon icon="streamline:ai-edit-spark-solid" />
      Preencher com IA
    </button>
  );
}

export default App;